window.$ = require('jquery');

// パスワード入力フォーム
$(function () {
    const $icon = $('.js-toggle-password');
    $icon.on('click', function () {
        const $input = $(this).prev('.js-password');
        // アイコンの状態変更
        if ($(this).hasClass('password-protected')) {
            // 非表示から表示のアイコンへ
            $input.attr('type', 'text');
            $(this).removeClass('password-protected');
        } else {
            // 表示から非表示のアイコンへ
            $input.attr('type', 'password');
            $(this).addClass('password-protected');
        }
    });
});

$(function () {
    $('.js-reload-btn').on('click', function () {
        window.location.reload();
    });
});

// ハンバーガーメニュー
$(function () {
    const $menu_button = $('.js-hamburger-menu-button');
    const $menu        = $('.js-hamburger-menu');
    $menu_button.on('click', function () {
        $menu.toggleClass('hamburger-menu-opened');
        $('body').toggleClass('noscroll');

        $(this).toggleClass('hamburger-menu-opened');
    });

    $(document).on('click', function (e) {
        if ($(e.target).hasClass('js-menu-close-disabled')) {
            return;
        }

        if (!$menu_button.is(e.target) && (!$menu.is(e.target) && $menu.has(e.target).length === 0)) {
            $menu.removeClass('hamburger-menu-opened');
            $('body').removeClass('noscroll');

            $menu_button.removeClass('hamburger-menu-opened');
        }
    });
});

$(function () {
    $('.js-accordion-icon').on('click', function(){
        $(this).toggleClass('open').next().slideToggle();
    });
});

$(function () {
    $('.js-changable-date.js-previous').on('click', function () {
        $('input.js-changable-date').val($(this).data('date'));
        auto_submit();
    });
    $('.js-changable-date.js-today').on('click', function () {
        $('input.js-changable-date').val($(this).data('date'));
        auto_submit();
    });
    $('.js-changable-date.js-next').on('click', function () {
        $('input.js-changable-date').val($(this).data('date'));
        auto_submit();
    });

    function auto_submit() {
        $('.js-auto-submit-date-form').trigger('submit');
    }
});

window.show_loading = function () {
    const loading_background = $('<div>', {
        css: {
            'position'  : 'fixed',
            'z-index'   : '99999999',
            'top'       : '0',
            'left'      : '0',
            'height'    : '100vh',
            'width'     : '100vw',
            'background': 'rgba(0, 0, 0, 0, 0)'
        },
        class: 'js-loading-background',
    });


    const loading_spinner = $('<div>', {
        css: {
            'position': 'fixed',
            'z-index' : '99999999',
            'top'     : '50%',
            'left'    : '50%',
        },
        class: 'spinner-border js-loading-spinner'
    });

    $('body').append(loading_background);
    $('body').append(loading_spinner);
}

window.hide_loading = function () {
    $('.js-loading-background').remove();
    $('.js-loading-spinner').remove();
}

window.is_api_error = function is_api_error(response) {
    return response.status === 'error';
}

window.show_errors = function show_errors(error_messages) {
    $.each(error_messages, function (index, message) {
        $('.js-modal-errors ul').append($('<li>', {
            text: message,
        }));
    });
    $('.js-modal-errors').removeClass('d-none');
}

window.hide_errors = function hide_errors() {
    $('.js-modal-errors').addClass('d-none');
    $('.js-modal-errors ul').children().remove();
}

window.show_errors_if = function show_errors_if(is_error, error_messages) {
    if (is_error) {
        show_errors(error_messages);
    }

    return is_error;
}

window.alert_system_error = function alert_system_error() {
    alert('予期しないエラーが発生しました。しばらく経ってから再度お試しください。改善しない場合は管理部に連絡してください。');

    return false;
}
